// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-generic-page-js": () => import("/opt/build/repo/src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-accessibility-page-js": () => import("/opt/build/repo/src/templates/accessibility-page.js" /* webpackChunkName: "component---src-templates-accessibility-page-js" */),
  "component---src-templates-branding-page-js": () => import("/opt/build/repo/src/templates/branding-page.js" /* webpackChunkName: "component---src-templates-branding-page-js" */),
  "component---src-templates-colors-page-js": () => import("/opt/build/repo/src/templates/colors-page.js" /* webpackChunkName: "component---src-templates-colors-page-js" */),
  "component---src-templates-cms-help-page-js": () => import("/opt/build/repo/src/templates/cms-help-page.js" /* webpackChunkName: "component---src-templates-cms-help-page-js" */),
  "component---src-templates-resource-page-js": () => import("/opt/build/repo/src/templates/resource-page.js" /* webpackChunkName: "component---src-templates-resource-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("/opt/build/repo/src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("/opt/build/repo/src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-development-alert-js": () => import("/opt/build/repo/src/pages/development/alert.js" /* webpackChunkName: "component---src-pages-development-alert-js" */),
  "component---src-pages-development-button-js": () => import("/opt/build/repo/src/pages/development/button.js" /* webpackChunkName: "component---src-pages-development-button-js" */),
  "component---src-pages-development-card-js": () => import("/opt/build/repo/src/pages/development/card.js" /* webpackChunkName: "component---src-pages-development-card-js" */),
  "component---src-pages-development-checkbox-js": () => import("/opt/build/repo/src/pages/development/checkbox.js" /* webpackChunkName: "component---src-pages-development-checkbox-js" */),
  "component---src-pages-development-dropdown-js": () => import("/opt/build/repo/src/pages/development/dropdown.js" /* webpackChunkName: "component---src-pages-development-dropdown-js" */),
  "component---src-pages-development-index-js": () => import("/opt/build/repo/src/pages/development/index.js" /* webpackChunkName: "component---src-pages-development-index-js" */),
  "component---src-pages-development-modal-js": () => import("/opt/build/repo/src/pages/development/modal.js" /* webpackChunkName: "component---src-pages-development-modal-js" */),
  "component---src-pages-development-radio-js": () => import("/opt/build/repo/src/pages/development/radio.js" /* webpackChunkName: "component---src-pages-development-radio-js" */),
  "component---src-pages-development-spinner-js": () => import("/opt/build/repo/src/pages/development/spinner.js" /* webpackChunkName: "component---src-pages-development-spinner-js" */),
  "component---src-pages-development-starter-kit-js": () => import("/opt/build/repo/src/pages/development/starter-kit.js" /* webpackChunkName: "component---src-pages-development-starter-kit-js" */),
  "component---src-pages-development-tab-js": () => import("/opt/build/repo/src/pages/development/tab.js" /* webpackChunkName: "component---src-pages-development-tab-js" */),
  "component---src-pages-development-tooltip-js": () => import("/opt/build/repo/src/pages/development/tooltip.js" /* webpackChunkName: "component---src-pages-development-tooltip-js" */),
  "component---src-pages-resources-certificate-generator-js": () => import("/opt/build/repo/src/pages/resources/certificate-generator.js" /* webpackChunkName: "component---src-pages-resources-certificate-generator-js" */),
  "component---src-pages-resources-email-signature-generator-js": () => import("/opt/build/repo/src/pages/resources/email-signature-generator.js" /* webpackChunkName: "component---src-pages-resources-email-signature-generator-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

